body{
    background: url(../public/assets/img/bg1.png) no-repeat left top fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
}

.product-container{
    display: flex;
    gap: 32px;
    padding-left: 32px;
    padding-right: 32px;
}

.card {
    background-color: rgba(45, 45, 50, 0.512);
    color: white;
    border: 2px solid #000000;
    border-radius: 10px;
    width: 250px;
}

.card-img-top{
    width: 228px;
    height: 228px;
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 11px;
}

.warenkorb{
    width: 20vw;
}

.main-container{
    display: flex;
    padding-right: 32px;
    padding-top: 32px;
}

@media (max-width: 850px){
.product-container{
    flex-direction: column;
}

}